import React from "react"
import styled from "styled-components"

import Seo from "../../components/seo"
import PageWrapper from "../../styles/PageWrapper"
import Row from "../../styles/Row"
import InfoTable from "./InfoTable"
import PendingInfoTable from "./PendingInfoTable"

const PatentsPageWrapper = styled(PageWrapper)`
  padding-top: 5rem;

  color: ${props => props.theme.color.primary};
`
const NarrowRow = styled(Row)`
  margin-bottom: 3rem;

  text-align: center;

  a {
    text-decoration: underline;
  }
`

const PatentsPage = () => (
  <PatentsPageWrapper>
    <Seo title="Patents" />
    <NarrowRow>
      <p>
        The following f'Real products are protected by one or more patents
        and/or pending patent applications in the U.S. and other jurisdictions.
        This list is posted on the Internet to satisfy the virtual patent
        marking provisions of various jurisdictions, including the virtual
        patent marking provisions of the America Invents Act, and provides
        notice to the public in compliance with 35 U.S.C. § 287.
      </p>
      <p>
        This website is subject to periodic maintenance and may not be available
        at the time of your visit. Due to the dynamic nature of the patent
        process and product lifecycles, this list may not be up to date at the
        specific time you visit this web page. For example, patent applications
        may be pending or published and patents may have issued which are not
        yet identified on this list. This list might not be all inclusive and
        other products not listed here may be associated with one or more
        patents or pending patent applications in the United States or other
        jurisdictions. The absence of any patent or product from this list does
        not preclude enforcement of any and all legal rights associated with the
        patent or product.
      </p>

      <h1>Issued Patents</h1>

      <InfoTable />

      <br />
      <br />

      <h1>Pending Patents</h1>
      <PendingInfoTable />
    </NarrowRow>
  </PatentsPageWrapper>
)

export default PatentsPage
